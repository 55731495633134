import { DOCUMENT } from '@angular/common';
import { ChangeDetectorRef, Inject, NgZone } from '@angular/core';
import { RowNode } from 'ag-grid-community';
import { RSocketClient } from 'rsocket-core';
import { ConnectionStatus, ReactiveSocket } from 'rsocket-types';
import { BehaviorSubject, Subject, Subscription } from 'rxjs';
import { ApiUrls } from 'src/app/core/classes/ApiUrls';
import { ModalService } from 'src/app/shared/services/modal/modal-service/modal.service';
import { SubSink } from 'subsink';
import { WidgetDocumentationMoreInfoModalComponent } from '../components/modal-templates/widget-documentation-modal/widget-documentation-more-info-modal/widget-documentation-more-info-modal.component';
import { ActionVisibility } from '../enums/ActionVisibility';
import { AttentionRequiredExceptions } from '../enums/AttentionRequiredExceptions';
import { AuthorizationType } from '../enums/AuthorizationType';
import { ContentType } from '../enums/ContentType';
import { FilterStoreKey } from '../enums/FilterStoreKey';
import { GraphType } from '../enums/GraphType';
import { ModalType } from '../enums/ModalType';
import { PortletType } from '../enums/PortletType';
import { RequestType } from '../enums/RequestType';
import { State } from '../enums/State';
import { SupportedFormat } from '../enums/SupportedFormat';
import { ViewType } from '../enums/ViewType';
import { IAction } from '../interfaces/actions/IAction';
import { IApiInfo } from '../interfaces/api/IApiInfo';
import { ApiResponseType } from '../interfaces/api/portlets/ApiResponse';
import { IHitApi } from '../interfaces/hit-api/IHitApi';
import { IIcon } from '../interfaces/icon-data/IIcon';
import { IModalData } from '../interfaces/modal/IModalData';
import { IAttentionRequired } from '../interfaces/widget/IAttentionRequired';
import { ILinkingData } from '../interfaces/widget/ILinkingData';
import { IWidgetConfig } from '../interfaces/widget/IWidgetConfig';
import { IWidgetConfigState } from '../interfaces/widget/IWidgetConfigState';
import { IWidgetData } from '../interfaces/widget/IWidgetData';
import {
    DescriptionPresignedUrlKeys,
    DocumentTypes,
    IDetailedDescription,
    IWidgetDescription
} from '../interfaces/widget/IWidgetDescription';
import { CartCacheService } from '../services/cache/cart-cache/cart-cache.service';
import { CompareViewService } from '../services/compare-view/compare-view.service';
import { FiltersService } from '../services/filters/filters.service';
import { GlobalDataService } from '../services/global-data/global-data.service';
import { HttpService } from '../services/http/http-main/http.service';
import { WidgetHttpService } from '../services/http/widget-http/widget-http.service';
import { NotificationsService } from '../services/notifications/notifications.service';
import { Helper } from './Helper';
import { HitApi } from './HitApi';
import { Logger } from './Logger';

export abstract class Widget {
    // Defaults
    readonly DEFAULT_BASIC_LIMIT = 0;
    readonly INTEGRATION_PORTLETS = [
        PortletType.GMAIL_INTEGRATION,
        PortletType.O365_INTEGRATION,
        PortletType.SLACK_INTEGRATION,
        PortletType.FRESHDESK_INTEGRATION,
        PortletType.FRESHSERVICE_INTEGRATION,
        PortletType.ZENDESK_INTEGRATION,
        PortletType.SNS_INTEGRATION,
        PortletType.SERVICENOW_INTEGRATION,
        PortletType.NAGIOS_INTEGRATION,
        PortletType.CSP_INTEGRATION,
        PortletType.EA_INTEGRATION,
        PortletType.PLAN_INTEGRATION,
        PortletType.AWS_INTEGRATION,
        PortletType.ZOHO_INTEGRATION,
        PortletType.JIRADESK_INTEGRATION,
        PortletType.OTRS_INTEGRATION,
        PortletType.AUTOTASK_INTEGRATION,
        PortletType.SAML_INTEGRATION,
        PortletType.CASDM_INTEGRATION,
        PortletType.GCP_INTEGRATION,
        PortletType.MCA_INTEGRATION,
        PortletType.SALESFORCE_INTEGRATION,
    ];

    readonly CUSTOM_STYLED_PORTLETS: PortletType[] = [
        PortletType.RIBBON,
        PortletType.AGGREGATE,
        PortletType.WHITELABEL_INTEGRATION,
        PortletType.WIDGET_CATALOG,
        ...this.INTEGRATION_PORTLETS,
    ];

    widgetData: IWidgetData;
    widgetLinkingData: ILinkingData;
    subSink: SubSink;
    uniqueIdentity: symbol;
    widgetContainerId: string;
    widgetTableId: string;
    lightState: boolean = false;
    widgetGraphId: string;
    widgetGraphLegendId: string;
    apiResponse: ApiResponseType;
    reportLoaders: Map<SupportedFormat, boolean> = new Map();
    operationLoaders: Map<string, boolean> = new Map();
    linkingMode: boolean = false;
    widgetIndex?: Number;
    hideLogicalOperatorRadioButtons: boolean;

    widgetText: BehaviorSubject<string> = new BehaviorSubject<string>(null);
    loadingData: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    headerIcon: BehaviorSubject<IIcon> = new BehaviorSubject<IIcon>(null);
    widgetConfigState: IWidgetConfigState;
    hideRefreshIcon: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
        false
    );
    widgetState: State;
    widgetTokenPrice: number;
    widgetUSDPrice: number;
    errorMessage: BehaviorSubject<string> = new BehaviorSubject<string>(null);
    extraMessage: BehaviorSubject<string> = new BehaviorSubject<string>(null);

    showViewIcon: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
        false
    );
    showAccordion: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
        false
    );
    widgetInCart: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
        false
    );
    widgetContentVisible: BehaviorSubject<boolean> =
        new BehaviorSubject<boolean>(true);
    widgetDescriptionVisible: BehaviorSubject<boolean> =
        new BehaviorSubject<boolean>(false);

    visibleSections: BehaviorSubject<Set<ViewType>> = new BehaviorSubject<
        Set<ViewType>
    >(new Set());
    widgetActions: BehaviorSubject<IAction[]> = new BehaviorSubject<IAction[]>(
        []
    );
    fixActions: BehaviorSubject<IAction[]> = new BehaviorSubject(null);
    //This action can be used to perform operations like bulkDelete , bulkEnable/Disable , remediation etc.
    operationalActions: BehaviorSubject<Map<string, IAction[]>> =
        new BehaviorSubject(new Map<string, IAction[]>());
    showNotificationBadge: BehaviorSubject<boolean> =
        new BehaviorSubject<boolean>(false);
    // Productization
    considerTokenization: boolean = false;

    // If true, it will either show graph or table, both can't be viewed together
    viewRestriction: boolean;

    tableRowClassRules;

    documentStyles;

    attentionRequiredList: IAttentionRequired[] = [];

    // for differentiating views of linking modal
    filterStoreKey: FilterStoreKey;

    widgetConfigDetails: IWidgetConfig;

    redrawSection;

    avoidDataGenOnRefresh: boolean = false;

    customInputCallback = null;

    isBindDataAssigned = false;
    cartWidgets = null;
    liteViewLimit: number = 4;
    liteViewCheckSelectionLimit: number = 1;

    // RSocket Streaming Vars
    streamCloseCallback;
    refreshWidgetCallback = null;
    listApiArgs: IHitApi = null;
    streamApiParams: {
        client: RSocketClient<BufferSource, BufferSource>;
        socket: ReactiveSocket<BufferSource, BufferSource>;
        connectionStatus: Subject<ConnectionStatus>;
    } = null;

    loadingPartialData: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
        false
    );
    // For partial data action loader to be handled separately
    actionLoading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
        false
    );
    listApiSubscription$: Subscription;
    // This is used for giving full width to a cell in tables
    setTimeoutToken: ReturnType<typeof setTimeout>;
    fullWidthCellRenderer: (rowNode: RowNode) => {};
    widgetMoreInfo: IDetailedDescription[];
    widgetDescription: IWidgetDescription;
    lastModifiedTime: string;
    private isPaginationEnabled: boolean = false;
    private pagination: { offSet: number; PageNumber: number } = {
        offSet: 20,
        PageNumber: 1
    };
    constructor(
        public httpService: HttpService,
        public globalDataService: GlobalDataService,
        public changeDetectorRef: ChangeDetectorRef,
        public notificationsService: NotificationsService,
        public ngZone: NgZone,
        @Inject(DOCUMENT) private document: Document,
        public filterService: FiltersService,
        public widgetHttpService: WidgetHttpService,
        public compareViewService: CompareViewService,
        public modalService: ModalService,
        public cartCache: CartCacheService
    ) {
        this.subSink = new SubSink();
        this.widgetContainerId = Helper.generateUniqueKey(10);
        this.widgetGraphId = Helper.generateUniqueKey(10);
        this.widgetTableId = Helper.generateUniqueKey(10);
        this.widgetGraphLegendId = `${this.widgetGraphId}legendDiv`;
        this.documentStyles = getComputedStyle(this.document.documentElement);
    }

    startPartialDataLoader() {
        if (!this.loadingPartialData.value) {
            this.loadingPartialData.next(true);
        }
    }
    endPartialDataLoader() {
        if (this.loadingPartialData.value) {
            this.loadingPartialData.next(false);
        }
    }

    startLoader() {
        if (!this.loadingData.value) {
            this.loadingData.next(true);
            if (
                this.changeDetectorRef &&
                !this.changeDetectorRef['destroyed']
            ) {
                this.changeDetectorRef.detectChanges();
            }
        }
    }

    endLoader() {
        if (this.loadingData.value) {
            this.loadingData.next(false);
            if (
                this.changeDetectorRef &&
                !this.changeDetectorRef['destroyed']
            ) {
                this.changeDetectorRef.detectChanges();
            }
        }
    }

    refreshWidget(forceRefresh = false) {
        if (
            (!this.loadingData.value && !this.actionLoading.value) ||
            forceRefresh
        ) {
            if (this.listApiSubscription$) {
                this.listApiSubscription$.unsubscribe();
            }

            if (this.refreshWidgetCallback) {
                this.refreshWidgetCallback();
            }
            if(this.isPaginationEnabled) {
                this.onChangePagination(this.bindData,1,20)
            } else {
                this.dataGenerator();
            }
        }
    }

    abstract bindData(data: ApiResponseType): void;

    abstract bindPartialData(data: ApiResponseType): void;

    /**
     * This method will reset Error and Extra Message
     */
    resetErrorAndExtraMessage() {
        this.httpService.resetIotTask.next(this.uniqueIdentity);
        this.errorMessage.next(null);
        this.extraMessage.next(null);
        this.startLoader();
    }

    dataGenerator(customInput?: any, callback?: any) {
        if (this.loadingData.value) {
            return;
        }
        if (callback) {
            this.customInputCallback = callback;
        }
        if (this.setTimeoutToken) {
            clearTimeout(this.setTimeoutToken);
        }
        if (
            this.widgetData.widgetInfo.list &&
            Object.keys(this.widgetData.widgetInfo.list).length &&
            !this.avoidDataGenOnRefresh
        ) {
            this.resetErrorAndExtraMessage();
            // Creating api arguments
            const args: IHitApi = Helper.generateHitApiConfig(
                this.widgetData.widgetInfo.list,
                // (response, extraData, headers) => {
                // this.lastModifiedTime = (headers as HttpHeaders).get(
                //     'Last-Modified'
                // );
                // if (this.lastModifiedTime) {
                //     this.lastModifiedTime = `${moment(this.lastModifiedTime)
                //         .utc()
                //         .format('L')} ${
                //         this.lastModifiedTime.split(' ')[4]
                //     } UTC`;
                //     if (
                //         this.globalDataService.widgetIdsOnCurrentPage.includes(
                //             this.widgetData.widgetId
                //         )
                //     ) {
                //         this.globalDataService.refreshWidgetsForCache.push(
                //             this
                //         );
                //     }
                // }
                this.bindData,
                // },
                this.bindPartialData,
                this.httpService.iotConfig
            );
            if (
                (args.url && args.url.includes('{widgetId}')) ||
                args.url.includes('{widget-id}')
            ) {
                args.intactUrl = args.url;
                args.url = args.url.replace(
                    '{widgetId}',
                    this.widgetData.widgetId
                );
                args.url = args.url.replace(
                    '{widget-id}',
                    this.widgetData.widgetId
                );
            }
            args.uniqueIdentity = this.uniqueIdentity;
            // args.errorMessage = this.errorMessage;
            args.updateUiFunction =
                this.changeDetectorRef.detectChanges.bind(this);
            args.extraMessage = this.extraMessage;
            args.errorFunction = (error) => {
                Logger.warn(error);
                // this.bindData(null);
                this.endLoader();
                this.actionLoading.next(false);
                this.errorMessage.next(Helper.extractErrorMessage(error));
                this.changeDetectorRef.detectChanges();
            };
            if (this.filterStoreKey) {
                args.input = this.filterService.prepareFiltersInputs(
                    this.widgetData.widgetInfo.filters,
                    this.filterStoreKey
                );
            } else {
                args.input = this.filterService.prepareFiltersInputs(
                    this.widgetData.widgetInfo.filters,
                    FilterStoreKey.WEBSITE_FILTERS
                );
            }
            if (
                this.widgetLinkingData &&
                Object.keys(this.widgetLinkingData.input).length
            ) {
                this.filterService.widgetLinkingData.next(
                    new Map().set(
                        this.filterStoreKey,
                        this.widgetLinkingData.input
                    )
                );
                Object.keys(this.widgetLinkingData.input).forEach((key) => {
                    if (
                        !(key in args.input) ||
                        this.widgetLinkingData.input[key].length !== 0
                    ) {
                        args.input[key] = this.widgetLinkingData.input[key];
                    }
                });
            }

            if (customInput) {
                args.input = { ...args.input, ...customInput };
            }
            if (this.customInputCallback) {
                args.input = { ...args.input, ...this.customInputCallback() };
            }
            args.returnApiCallSubscription = (subscription) => {
                this.listApiSubscription$ = subscription;
            };
            this.listApiArgs = args;

            if (
                this.widgetData.apiResponse &&
                !args.streamData &&
                !args.iotStreamData
            ) {
                this.bindData(this.widgetData.apiResponse);
            } else if (args.streamData) {
                args.initiatorWidgetId = this.uniqueIdentity;
                if (this.streamCloseCallback) {
                    args.streamCloseCallback =
                        this.streamCloseCallback.bind(this);
                }
                this.streamApiParams = new HitApi(
                    args,
                    this.httpService,
                    this.ngZone
                ).hitStreamApi();
            } else if (args.iotStreamData) {
                args.iotStreamData.iotInput.connectionSuccessCallback = (
                    response
                ) => {
                    if (!args.input) {
                        args.input = {};
                    }
                    args.input = {
                        ...args.input,
                        topicId: args?.iotStreamData?.iotInput?.topic,
                        deploymentEndpoint:
                            args?.iotStreamData?.iotInput?.endpoint,
                    };
                    args.function = null;
                    args.errorFunction = (message) => {
                        Logger.warn(message);
                        this.endLoader();
                        this.actionLoading.next(false);
                        this.errorMessage.next(Helper.extractErrorMessage(message));
                        this.changeDetectorRef.detectChanges();
                    };
                    new HitApi(args, this.httpService, this.ngZone).hitApi();
                };

                new HitApi(
                    args,
                    this.httpService,
                    this.ngZone
                ).hitIotStreamApi();
            } else {
                new HitApi(args, this.httpService, this.ngZone).hitApi();
            }
        } else if (this.avoidDataGenOnRefresh) {
            // Resetting Error and Extra Message
            this.errorMessage.next(null);
            this.extraMessage.next(null);
            this.startLoader();
            this.bindData(null);
        }
        //  else {
        //     this.bindData(null);
        // }
    }

    /**
     * handles pagination for widgets in which pagination functionality is implemented.
     * @param bindDataFunc - Accepts bindData Function 
     * @param pageNumber -  To pass PageNumber in the params
     * @param offSet  - To pass pageSize in the params 
     * @param dataGenCall
     */
    onChangePagination(
        bindDataFunc?,
        pageNumber?: number,
        offSet?: number,
        dataGenCall = true
    ) {
        this.isPaginationEnabled= true;
        this.pagination.offSet=offSet;
        this.pagination.PageNumber=pageNumber;
        this.isBindDataAssigned = true;
        this.avoidDataGenOnRefresh = !dataGenCall;
        this.bindData = bindDataFunc;
        if (this.setTimeoutToken) {
            clearTimeout(this.setTimeoutToken);
        }
        if (
            this.widgetData.widgetInfo.list &&
            Object.keys(this.widgetData.widgetInfo.list).length &&
            !this.avoidDataGenOnRefresh
        ) {
            this.resetErrorAndExtraMessage();
            const args: IHitApi = Helper.generateHitApiConfig(
                this.widgetData.widgetInfo.list,
                this.bindData,
                this.bindPartialData,
                this.httpService.iotConfig
            );
            if (args.url.includes('search')) {
                args.url = args.url.replace(
                    'search/{pageNo}/{offset}',
                    `${pageNumber}/${offSet}`
                );
            } else {
                if (args.url.includes('offset')) {
                    args.url = args.url.replace(
                        '{pageNo}/{offset}',
                        `${pageNumber}/${offSet}`
                    );
                } else {
                    args.url = args.url + `${pageNumber}/${offSet}`;
                }
            }
            args.uniqueIdentity = this.uniqueIdentity;
            args.updateUiFunction =
                this.changeDetectorRef.detectChanges.bind(this);
            args.extraMessage = this.extraMessage;
            args.errorFunction = (error) => {
                Logger.warn(error);
                this.endLoader();
                this.actionLoading.next(false);
                this.errorMessage.next(Helper.extractErrorMessage(error));
                this.changeDetectorRef.detectChanges();
            };
            if (this.filterStoreKey) {
                args.input = this.filterService.prepareFiltersInputs(
                    this.widgetData.widgetInfo.filters,
                    this.filterStoreKey
                );
            } else {
                args.input = this.filterService.prepareFiltersInputs(
                    this.widgetData.widgetInfo.filters,
                    FilterStoreKey.WEBSITE_FILTERS
                );
            }

            args.returnApiCallSubscription = (subscription) => {
                this.listApiSubscription$ = subscription;
            };
            this.listApiArgs = args;
            new HitApi(args, this.httpService, this.ngZone).hitApi();
        }
    }

    dummyDataGenerator() {}

    attentionRequired(response: ApiResponseType) {
        this.attentionRequiredList = [];
        if (
            (response['generalException'] &&
                response['generalException'].length) ||
            (response['insufficientPermission'] &&
                response['insufficientPermission'].length) ||
            (response['invalidCredentials'] &&
                response['invalidCredentials'].length) ||
            (response['noCredentialsDB'] && response['noCredentialsDB'].length)
        ) {
            this.attentionRequiredDataBinder(response);
            this.widgetConfigState.actions.attention.visibility =
                ActionVisibility.VISIBLE;
        } else {
            this.widgetConfigState.actions.attention.visibility =
                ActionVisibility.HIDDEN;
        }

        this.endLoader();
    }

    attentionRequiredDataBinder(response: ApiResponseType) {
        if (
            response['generalException'] &&
            response['generalException'].length
        ) {
            const [heading, value] = [
                AttentionRequiredExceptions.generalException,
                response['generalException'],
            ];
            this.attentionRequiredList.push({ heading, value });
        }
        if (
            response['insufficientPermission'] &&
            response['insufficientPermission'].length
        ) {
            const [heading, value] = [
                AttentionRequiredExceptions.insufficientPermission,
                response['insufficientPermission'],
            ];
            this.attentionRequiredList.push({ heading, value });
        }
        if (
            response['invalidCredentials'] &&
            response['invalidCredentials'].length
        ) {
            const [heading, value] = [
                AttentionRequiredExceptions.invalidCredentials,
                response['invalidCredentials'],
            ];
            this.attentionRequiredList.push({ heading, value });
        }
        if (response['noCredentialsDB'] && response['noCredentialsDB'].length) {
            const [heading, value] = [
                AttentionRequiredExceptions.noCredentialsDB,
                response['noCredentialsDB'],
            ];
            this.attentionRequiredList.push({ heading, value });
        }
    }

    setBindData(
        bindDataFunc,
        dataGenCall = true,
        customInput?: any,
        callback?: any
    ) {
        if (this.isBindDataAssigned) {
            return;
        }
        this.isBindDataAssigned = true;
        this.bindData = bindDataFunc;
        this.avoidDataGenOnRefresh = !dataGenCall;
        if (dataGenCall && !this.loadingData.value) {
            this.dataGenerator(customInput, callback);
        } else if (!this.loadingData.value) {
            this.dataGenerator();
        }
    }

    setBindPartialData(bindPartialDataFunc, dataGenCall = true) {
        this.bindPartialData = bindPartialDataFunc;
        if (dataGenCall) {
            this.dataGenerator();
        }
    }

    setRedrawSection(redrawSectionFunc) {
        this.redrawSection = redrawSectionFunc;
    }

    downloadReport(reportType: SupportedFormat, reportApiInfo: IApiInfo) {
        if (this.reportLoaders.get(reportType)) return;
        if (
            !this.filterService.filtersFormData.size ||
            !this.filterService.filtersFormData.has(this.filterStoreKey) ||
            (this.filterService.filtersFormData.has(this.filterStoreKey) &&
                !this.filterService.filtersFormData.get(this.filterStoreKey)
                    .size)
        ) {
            this.filterService.setFilterInputs.next(true);
        }
        this.reportLoaders.set(reportType, true);
        const hitApi: IHitApi = Helper.generateHitApiConfig(reportApiInfo);

        if (hitApi.url.includes('contentType=pdf')) {
            hitApi.config.downloadable = true;
            hitApi.config.defaultHeaders = {
                Accept: ContentType.PDF,
            };
        } else if (hitApi.url.includes('contentType=csv')) {
            hitApi.config.downloadable = true;
            hitApi.config.defaultHeaders = {
                Accept: ContentType.CSV,
            };
        } else if (hitApi.url.includes('contentType=excel')) {
            hitApi.config.downloadable = true;
            hitApi.config.defaultHeaders = {
                Accept: ContentType.EXCEL,
            };
        }

        hitApi.function = (response) => {
            if (hitApi.config.downloadable) {
                Helper.saveAsBlob(
                    response,
                    hitApi.config.defaultHeaders.Accept,
                    this.widgetData.widgetInfo.text
                );
            } else if (
                response &&
                response['dataMap'] &&
                (response['dataMap']['preSignedURL'] ||
                    response['dataMap']['preSignedUrl'])
            ) {
                Helper.downloadReport(
                    response['dataMap']['preSignedURL']
                        ? response['dataMap']['preSignedURL']
                        : response['dataMap']['preSignedUrl'],
                    reportType,
                    this.widgetData.widgetInfo.text,
                    this.httpService,
                    this.ngZone
                );
            }

            this.notificationsService.showSnackBar(
                'Report successfully downloaded'
            );
        };
        hitApi.endFunction = () => {
            this.reportLoaders.set(reportType, false);
            if (
                this.changeDetectorRef &&
                !this.changeDetectorRef['destroyed']
            ) {
                this.changeDetectorRef.detectChanges();
            }
        };
        hitApi.errorFunction = (error) => {
            if (hitApi.url.includes('contentType=')) {
                if (error.status === 413) {
                    this.notificationsService.showSnackBar(
                        'File size limit exceeded. PDF files of up to 5 MB can be downloaded at once.',
                        true
                    );
                } else {
                    error.error
                        .text()
                        .then((error) => {
                            this.notificationsService.showSnackBar(
                                JSON.parse(error).message,
                                true
                            );
                        })
                        .catch(Logger.warn);
                }
            } else {
                Helper.showErrorMessage(
                    this.notificationsService,
                    error,
                    'Error downloading report'
                );
            }
        };
        if (!hitApi.input) {
            hitApi.input = {};
        }

        if (this.filterStoreKey) {
            hitApi.input = {
                ...hitApi.input,
                ...this.filterService.prepareFiltersInputs(
                    this.widgetData.widgetInfo.filters,
                    this.filterStoreKey
                ),
            };
        } else {
            hitApi.input = {
                ...hitApi.input,
                ...this.filterService.prepareFiltersInputs(
                    this.widgetData.widgetInfo.filters,
                    FilterStoreKey.WEBSITE_FILTERS
                ),
            };
        }

        if (this.widgetLinkingData && this.widgetLinkingData.input) {
            Object.keys(this.widgetLinkingData.input).forEach((key) => {
                if (this.widgetLinkingData.input[key])
                    hitApi.input[key] = this.widgetLinkingData.input[key];
            });
        }
        const http = new HitApi(hitApi, this.httpService, this.ngZone);
        http.hitApi();
    }

    performAction(widgetAction: IAction, actionId?: any): void {
        const visibleSections = this.visibleSections.value;
        const viewClicked = widgetAction.type;
        const isToggled = visibleSections.has(viewClicked);
        if (viewClicked === ViewType.GRAPH && isToggled) {
            visibleSections.delete(viewClicked);
            if (!visibleSections.has(ViewType.TABLE)) {
                visibleSections.add(ViewType.TABLE);
            }

            let widgetActions: IAction[];
            if (widgetAction.graphType === GraphType.LINE) {
                widgetActions = [
                    this.widgetConfigState.actions.lineGraph,
                    this.widgetConfigState.actions.table,
                ];
            } else if (widgetAction.graphType === GraphType.PIE) {
                widgetActions = [
                    this.widgetConfigState.actions.pieGraph,
                    this.widgetConfigState.actions.table,
                ];
            } else if (widgetAction.graphType === GraphType.MAP) {
                widgetActions = [
                    this.widgetConfigState.actions.regionalGraph,
                    this.widgetConfigState.actions.table,
                ];
            } else {
                widgetActions = [
                    this.widgetConfigState.actions.graph,
                    this.widgetConfigState.actions.table,
                ];
            }
            this.widgetActions.next(widgetActions);
        } else if (viewClicked === ViewType.TABLE && isToggled) {
            visibleSections.delete(viewClicked);
            if (!visibleSections.has(ViewType.GRAPH)) {
                visibleSections.add(ViewType.GRAPH);
                visibleSections.add(ViewType.LEGEND);
            }
        } else if (viewClicked === ViewType.GRAPH && !isToggled) {
            if (this.viewRestriction && visibleSections.has(ViewType.TABLE)) {
                visibleSections.delete(ViewType.TABLE);
            } else if (
                this.viewRestriction &&
                visibleSections.has(ViewType.STACKED_BAR_GRAPH)
            ) {
                visibleSections.delete(ViewType.STACKED_BAR_GRAPH);
            }
            visibleSections.add(viewClicked);
            visibleSections.add(ViewType.LEGEND);
        } else if (viewClicked === ViewType.TABLE && !isToggled) {
            if (this.viewRestriction && visibleSections.has(ViewType.GRAPH)) {
                visibleSections.delete(ViewType.GRAPH);
            } else if (
                this.viewRestriction &&
                visibleSections.has(ViewType.STACKED_BAR_GRAPH)
            ) {
                visibleSections.delete(ViewType.STACKED_BAR_GRAPH);
            }
            visibleSections.add(viewClicked);
        } else if (viewClicked === ViewType.LEGEND && isToggled) {
            visibleSections.delete(viewClicked);
        } else if (viewClicked === ViewType.LEGEND && !isToggled) {
            visibleSections.add(viewClicked);
        } else if (viewClicked === ViewType.CARDS && !isToggled) {
            visibleSections.delete(ViewType.TABLE);
            if (!visibleSections.has(ViewType.CARDS)) {
                visibleSections.add(ViewType.CARDS);
            }
        } else if (viewClicked === ViewType.CARDS && isToggled) {
            visibleSections.delete(ViewType.CARDS);
            if (!visibleSections.has(ViewType.TABLE)) {
                visibleSections.add(ViewType.TABLE);
            }
        } else if (viewClicked === ViewType.STACKED_BAR_GRAPH && isToggled) {
            visibleSections.delete(viewClicked);
            if (!visibleSections.has(ViewType.GRAPH)) {
                visibleSections.add(ViewType.GRAPH);
            }
        } else if (viewClicked === ViewType.STACKED_BAR_GRAPH && !isToggled) {
            if (this.viewRestriction) {
                if (visibleSections.has(ViewType.GRAPH)) {
                    visibleSections.delete(ViewType.GRAPH);
                } else if (visibleSections.has(ViewType.TABLE)) {
                    visibleSections.delete(ViewType.TABLE);
                }
                visibleSections.add(viewClicked);
                visibleSections.add(ViewType.LEGEND);
            }
        }
        this.visibleSections.next(visibleSections);
        if (
            (visibleSections.has(ViewType.GRAPH) ||
                visibleSections.has(ViewType.STACKED_BAR_GRAPH)) &&
            this.redrawSection
        ) {
            this.redrawSection(this.apiResponse);
        }

        if (widgetAction.function) {
            widgetAction.function(actionId ? actionId : null);
        }
    }

    addWidgetToCart() {
        const selectedWidgets = JSON.parse(this.cartCache.cartSelectedWidgets);
        if (this.cartCache && this.cartCache.cartWidgets) {
            this.cartWidgets = JSON.parse(this.cartCache.cartWidgets);
        } else {
            this.cartWidgets = {};
        }
        if (!this.widgetInCart.getValue()) {
            this.cartWidgets[this.widgetData.widgetId] =
                this.widgetData.widgetInfo.text;
            this.cartCache.cartWidgets = JSON.stringify(this.cartWidgets);
            this.notificationsService.showSnackBar('Added to cart');
            this.widgetInCart.next(true);
        } else {
            delete this.cartWidgets[this.widgetData.widgetId];
            this.cartCache.cartWidgets = JSON.stringify(this.cartWidgets);
            if (
                selectedWidgets &&
                this.widgetData.widgetId in selectedWidgets
            ) {
                delete selectedWidgets[this.widgetData.widgetId];
                this.cartCache.cartSelectedWidgets =
                    JSON.stringify(selectedWidgets);
            }
            if (!Object.keys(this.cartWidgets).length) {
                this.cartCache.deleteCollection();
            }
            this.notificationsService.showSnackBar('Removed from cart');
            this.widgetInCart.next(false);
        }
        this.globalDataService.updateSelectedWidgets.next(true);
        this.globalDataService.updateCartCount.next(true);
    }

    updateSelectedWidget() {
        if (this.cartCache && this.cartCache.cartWidgets) {
            this.cartWidgets = JSON.parse(this.cartCache.cartWidgets);
            if (this.widgetData.widgetId in this.cartWidgets) {
                this.widgetInCart.next(true);
            } else {
                this.widgetInCart.next(false);
            }
        } else {
            this.widgetInCart.next(false);
        }
    }

    hitWidgetMoreInfoApi(
        widgetId,
        skipMoreInfoModal = false,
        callback?,
        hideErrorMessage = false,
        isProduction = false
    ) {
        const url = isProduction
            ? ApiUrls.WIDGET_DOCUMENTATION_PUBLISHED_DETAILED_DESCRIPTION
            : ApiUrls.WIDGET_DOCUMENTATION_DRAFT_DETAILED_DESCRIPTION;

        const hitApi: IHitApi = {
            url: url.replace('{widgetId}', widgetId),
            intactUrl: url,
            requestType: RequestType.GET,
            uniqueIdentity: Symbol(),
            config: {
                authorization: AuthorizationType.BEARER_TOKEN,
            },
            function: (response) => {
                const showError = () => {
                    Helper.showErrorMessage(
                        this.notificationsService,
                        null,
                        'No more information found with this widget'
                    );
                };

                if (response && response['presignedUrl']) {
                    this.generateWidgetMoreInfoData(
                        response['presignedUrl'],
                        skipMoreInfoModal,
                        callback ? callback : null
                    );
                } else {
                    if (!hideErrorMessage) {
                        showError();
                    }

                    if (callback) {
                        callback([]);
                    }
                }
            },
            errorFunction: (error) => {
                Helper.showErrorMessage(
                    this.notificationsService,
                    error,
                    'Error getting data.'
                );

                if (callback) {
                    callback([]);
                }
            },
            input: {},
        };

        new HitApi(hitApi, this.httpService, this.ngZone).hitApi();
    }

    generateWidgetMoreInfoData(
        presignedURLsObject,
        skipMoreInfoModal = false,
        callback?
    ) {
        const allDescription: IDetailedDescription[] = [];

        const urlKeys = Object.keys(presignedURLsObject);

        if (urlKeys.length) {
            let responseCount = 0;
            let apiCount = 0;

            const openModal = () => {
                if (apiCount === responseCount) {
                    if (!skipMoreInfoModal) {
                        this.openMoreInfoModal(allDescription);
                    }
                    if (callback) {
                        callback(allDescription);
                    }
                }
            };

            urlKeys.map((eachUrl) => {
                apiCount = apiCount + 1;

                if (
                    eachUrl !== DescriptionPresignedUrlKeys.IMAGES &&
                    eachUrl !== DescriptionPresignedUrlKeys.VIDEOS &&
                    eachUrl !== 'videoPresignedUrl'
                ) {
                    const apiArgs: IHitApi = {
                        url: presignedURLsObject[eachUrl],
                        requestType: RequestType.GET,
                        config: {
                            ignoreBaseUrl: true,
                            authorization: null,
                            responseType: 'text',
                        },
                        function: (response) => {
                            responseCount = responseCount + 1;
                            const eachDescription: IDetailedDescription = {
                                type: null,
                                content: null,
                            };
                            switch (eachUrl) {
                                case DescriptionPresignedUrlKeys.DESCRIPTION:
                                    eachDescription.type =
                                        DocumentTypes.DESCRIPTION;
                                    break;

                                case DescriptionPresignedUrlKeys.DETAILED_DESCRIPTION:
                                    eachDescription.type =
                                        DocumentTypes.DETAILED_DESCRIPTION;
                                    break;

                                case DescriptionPresignedUrlKeys.REFERENCE:
                                    eachDescription.type =
                                        DocumentTypes.REFERENCE;
                                    break;

                                case DescriptionPresignedUrlKeys.USE_CASES:
                                    eachDescription.type =
                                        DocumentTypes.USE_CASES;
                                    break;

                                case DescriptionPresignedUrlKeys.FAQ:
                                    eachDescription.type = DocumentTypes.FAQ;
                                    break;

                                default:
                                    break;
                            }

                            eachDescription.content =
                                Helper.convertMarkdownToHtml(
                                    response ? response : ''
                                );

                            allDescription.push(eachDescription);

                            openModal();
                        },
                        input: {},
                        uniqueIdentity: Symbol(),
                    };

                    new HitApi(apiArgs, this.httpService, this.ngZone).hitApi();
                } else {
                    if (
                        eachUrl === DescriptionPresignedUrlKeys.IMAGES &&
                        presignedURLsObject[eachUrl] &&
                        presignedURLsObject[eachUrl].length
                    ) {
                        const eachDescription: IDetailedDescription = {
                            type: DocumentTypes.IMAGES,
                            content: null,
                            links: presignedURLsObject[eachUrl].map((each) => {
                                return {
                                    name: each.imageKey,
                                    link: each.imageUrl,
                                };
                            }),
                        };
                        responseCount = responseCount + 1;
                        allDescription.push(eachDescription);
                        openModal();
                    } else if (eachUrl === DescriptionPresignedUrlKeys.VIDEOS) {
                        const eachDescription: IDetailedDescription = {
                            type: DocumentTypes.VIDEOS,
                            content: null,
                            links: [
                                {
                                    name: presignedURLsObject[
                                        DescriptionPresignedUrlKeys.VIDEOS
                                    ],
                                    link: presignedURLsObject.videoPresignedUrl
                                        ? presignedURLsObject.videoPresignedUrl
                                        : '',
                                },
                            ],
                        };
                        responseCount = responseCount + 2;
                        allDescription.push(eachDescription);
                        openModal();
                    }
                }
            });
        } else {
            if (!skipMoreInfoModal) {
                Helper.showErrorMessage(
                    this.notificationsService,
                    null,
                    'No more information found with this widget'
                );
            }

            if (callback) {
                callback([]);
            }
        }
    }

    openMoreInfoModal(previewData) {
        const modalData: IModalData = {
            modalName: '',
            modalIcon: null,
            modalType: ModalType.SIDE,
            sourceId: Symbol(),
            modalWidthVw: 75,
            modalHeightVh: 80,
            hideSteps: true,
            noHeader: true,
            noStepPadding: true,
            modalSteps: [
                {
                    stepData: {
                        componentToLoad:
                            WidgetDocumentationMoreInfoModalComponent,
                        payload: {
                            data: {
                                previewData,
                                widgetRef: this,
                            },
                        },
                    },
                    stepName: '',
                },
            ],
        };
        this.modalService.openModal(modalData);
    }
}
