<div class="bottom-ellipse" appIcon [data]="bottomEllipse"></div>
<div class="top-ellipse" appIcon [data]="topEllipse"></div>
<div class="login-wrapper">
    <div class="logo-container">
        <div class="logo">
            <a
                appIcon
                [data]="logo"
                [href]="
                    whiteLabelService.whiteLableData.organizationalUrl
                        ? whiteLabelService.whiteLableData.organizationalUrl.includes(
                              'http'
                          )
                            ? whiteLabelService.whiteLableData.organizationalUrl
                            : '//' +
                              whiteLabelService.whiteLableData.organizationalUrl
                        : ''
                "
                target="_blank"
                rel="noopener noreferrer"
            >
            </a>
        </div>
    </div>
    <div class="login-container">
        <div appIcon [data]="loginImage" class="work"></div>
        <div class="form">
            <ng-container *ngFor="let form of formSections">
                <div *ngIf="visibleSection === form" class="form-container">
                    <mat-progress-bar
                        class="progress-bar"
                        *ngIf="loading"
                        mode="indeterminate"
                    ></mat-progress-bar>
                    <div class="headingAndBackButton">
                        <div class="back-button-container">
                            <button
                                (click)="goBack()"
                                type="button"
                                class="back-button btn btn-grey clickable"
                                *ngIf="visibleSection != LoginSection.DOMAINID"
                            >
                                <i
                                    title="Go back"
                                    class="fas fa-arrow-left noSpace"
                                ></i>
                            </button>
                        </div>
                        <div class="head">
                            <span
                                class="signIn"
                                *ngIf="
                                    visibleSection === LoginSection.CREDENTIALS
                                "
                                >SIGN IN</span
                            >
                            <span
                                class="signIn"
                                *ngIf="
                                    visibleSection === LoginSection.SSO_EMAIL
                                "
                                >SIGN IN</span
                            >
                            <span *ngIf="visibleSection === LoginSection.MFA"
                                >MFA</span
                            >
                            <span
                                *ngIf="visibleSection === LoginSection.SUPPORT"
                                >SUPPORT</span
                            >

                            <span
                                class="retrievePassword"
                                *ngIf="visibleSection === LoginSection.EMAIL"
                                >RETRIEVE PASSWORD</span
                            >
                            <span
                                class="domainid"
                                *ngIf="visibleSection === LoginSection.DOMAINID"
                                >ENTER DOMAIN ID</span
                            >

                            <span
                                class="retrievePassword"
                                *ngIf="
                                    visibleSection === LoginSection.RESET &&
                                    resetPasswordFields
                                "
                                >RESET PASSWORD</span
                            >
                            <span
                                class="acceptLicense"
                                *ngIf="
                                    visibleSection === LoginSection.RESET &&
                                    checkboxSection &&
                                    !resetPasswordFields
                                "
                                >Accept License</span
                            >
                        </div>
                    </div>
                    <div class="one-liner">
                        <span *ngIf="visibleSection === LoginSection.EMAIL"
                            >To retrieve your password please enter your email
                            Id</span
                        >
                    </div>
                    <div class="login-content">
                        <div class="fieldsWithErrorAndForgotPassword">
                            <div
                                class="fields"
                                *ngIf="
                                    visibleSection !==
                                        LoginSection.CREDENTIALS &&
                                    visibleSection !== LoginSection.SUPPORT
                                "
                            >
                                <div class="terms">
                                    <app-form-generator
                                        (formSubmitted)="authenticate()"
                                        *ngIf="
                                            visibleSection ===
                                                LoginSection.RESET &&
                                            resetPasswordFields
                                        "
                                        [formGeneratorInput]="
                                            resetPasswordFormGenInput
                                        "
                                        (formGroupRef)="
                                            resetPasswordFormGroup = $event
                                        "
                                    ></app-form-generator>
                                    <div
                                        class="checkbox"
                                        [ngClass]="{
                                            'preview-console-terms-services':
                                                isPreviewTermsAndServices,
                                            'preview-console-terms-hidden':
                                                isPreviewTermsHidden
                                        }"
                                        *ngIf="
                                            visibleSection ===
                                                LoginSection.RESET &&
                                            checkboxSection &&
                                            (isPreviewTermsAndServices ||
                                                isShowLicenseAgreement)
                                        "
                                    >
                                        <div
                                            style="display: none"
                                            [ngClass]="{
                                                'preview-console-terms-services-tooltip':
                                                    isPreviewTermsAndServices
                                            }"
                                        >
                                            {{
                                                isPreviewTermsHidden
                                                    ? 'The checkbox to accept the "Terms of Service" will not be visible.'
                                                    : "The updated file will be shown here"
                                            }}
                                        </div>
                                        <mat-checkbox
                                            name="checkbox"
                                            [checked]="false"
                                            (change)="
                                                licenseAccepted = $event.checked
                                            "
                                            >I accept
                                            <a
                                                [href]="termsOfServicesUrl"
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                [class.disabled]="isPreviewTermsAndServices"
                                                >Terms and Conditions</a
                                            >
                                        </mat-checkbox>
                                        <ng-container
                                            *ngIf="
                                                checkedError && !licenseAccepted
                                            "
                                        >
                                            <mat-error>
                                                Please accept the license
                                                agreement
                                            </mat-error>
                                        </ng-container>
                                    </div>
                                </div>
                                <div>
                                    <app-form-generator
                                        (formSubmitted)="authenticate()"
                                        *ngIf="
                                            visibleSection ===
                                            LoginSection.DOMAINID
                                        "
                                        [formGeneratorInput]="
                                            domainFormGenInput
                                        "
                                        (formGroupRef)="
                                            domainFormGroup = $event
                                        "
                                    ></app-form-generator>
                                </div>
                                <div class="email">
                                    <app-form-generator
                                        (formSubmitted)="authenticate()"
                                        *ngIf="
                                            visibleSection ===
                                            LoginSection.EMAIL
                                        "
                                        [formGeneratorInput]="
                                            forgetPasswordFormGenInput
                                        "
                                        (formGroupRef)="
                                            forgetPasswordFormGroup = $event
                                        "
                                    ></app-form-generator>
                                    <div
                                        class="recoveryMessage"
                                        *ngIf="emailRecoveryMessage"
                                    >
                                        An email has been sent to you with
                                        password recovery instructions.
                                    </div>
                                </div>

                                <div class="sso-email">
                                    <app-form-generator
                                        (formSubmitted)="authenticate()"
                                        *ngIf="
                                            visibleSection ===
                                            LoginSection.SSO_EMAIL
                                        "
                                        [formGeneratorInput]="
                                            ssoEmailFormGenInput
                                        "
                                        (formGroupRef)="
                                            ssoEmailFormGroup = $event
                                        "
                                    ></app-form-generator>
                                </div>
                                <div
                                    *ngIf="visibleSection === LoginSection.MFA"
                                >
                                    <app-form-generator
                                        [formGeneratorInput]="mfaFormGenInput"
                                        (formGroupRef)="mfaFormGroup = $event"
                                    ></app-form-generator>
                                </div>
                            </div>
                            <div
                                class="credentials-field"
                                *ngIf="
                                    visibleSection === LoginSection.CREDENTIALS
                                "
                            >
                                <app-form-generator
                                    (formSubmitted)="authenticate()"
                                    *ngIf="
                                        visibleSection ===
                                        LoginSection.CREDENTIALS
                                    "
                                    [formGeneratorInput]="
                                        credentialsFormGenInput
                                    "
                                    (formGroupRef)="
                                        credentialsFormGroup = $event
                                    "
                                ></app-form-generator>
                            </div>
                            <div
                                class="support"
                                *ngIf="visibleSection === LoginSection.SUPPORT"
                            >
                                <app-form-generator
                                    (formSubmitted)="authenticate()"
                                    *ngIf="
                                        visibleSection === LoginSection.SUPPORT
                                    "
                                    [formGeneratorInput]="supportFormGenInput"
                                    (formGroupRef)="supportFormGroup = $event"
                                >
                                </app-form-generator>
                            </div>
                            <div class="buttons">
                                <app-multi-button-generator
                                    [buttonGenInputs]="buttonGenInput"
                                >
                                </app-multi-button-generator>
                                <div class="privacy-policy" *ngIf="isShouldUsePrivacyPolicyUpdate">By clicking sign in, you have read and agreed to our updated<span><a class="privacy-policy-link" [href]="privacyPolicyLink ? privacyPolicyLink : ''" target="_blank" [matTooltip]= "privacyPolicyLink ? '' : 'Due to technical reasons, currently unavailable'"
                                    [matTooltipPosition]="privacyPolicyLink ? '' : 'below'"><b> Privacy Policy</b></a></span>
                                </div>
                            </div>
                            <div class="link">
                                <a
                                    style="margin-right: auto"
                                    (click)="
                                        resetDomain();
                                        moveTo(LoginSection.CREDENTIALS)
                                    "
                                    *ngIf="
                                        visibleSection ===
                                            LoginSection.DOMAINID &&
                                        !assesseeCreds
                                    "
                                    class="clickable"
                                    >Root User?</a
                                >
                                <a
                                    (click)="moveTo(LoginSection.SSO_EMAIL)"
                                    class="clickable"
                                    *ngIf="
                                        visibleSection ===
                                            LoginSection.DOMAINID &&
                                        !assesseeCreds
                                    "
                                    >Login with SSO</a
                                >
                                <a
                                    (click)="moveTo(LoginSection.EMAIL)"
                                    *ngIf="
                                        visibleSection ===
                                        LoginSection.CREDENTIALS
                                    "
                                    class="clickable"
                                    style="margin-left: auto"
                                    >Forgot Password?</a
                                >
                                <i
                                    class="extra"
                                    matTooltip="Resend Code"
                                    matTooltipPosition="below"
                                    [class.disabled]="!enableResendbtn"
                                    *ngIf="visibleSection === LoginSection.MFA"
                                    class="fas fa-redo-alt clickable"
                                    (click)="resendOtp()"
                                ></i>
                                <div
                                    class="timer"
                                    *ngIf="
                                        visibleSection === LoginSection.MFA &&
                                        showTimer
                                    "
                                >
                                    <span *ngIf="timer | async"
                                        >{{ timer | async }} Sec</span
                                    >
                                </div>
                            </div>
                            <div
                                class="first-time"
                                *ngIf="
                                    visibleSection === LoginSection.DOMAINID &&
                                    whiteLabelService.whiteLableData
                                        .organizationalUrl &&
                                    (globalDataService.viaMicrosoftTeams
                                        | async)
                                "
                            >
                                <a
                                    class="clickable"
                                    [href]="
                                        whiteLabelService.whiteLableData
                                            .organizationalUrl
                                            ? whiteLabelService.whiteLableData.organizationalUrl.includes(
                                                  'http'
                                              )
                                                ? whiteLabelService
                                                      .whiteLableData
                                                      .organizationalUrl
                                                : '//' +
                                                  whiteLabelService
                                                      .whiteLableData
                                                      .organizationalUrl
                                            : ''
                                    "
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    >First Time Here?</a
                                >
                            </div>
                            <div class="messages">
                                <div class="error-messages">
                                    <i
                                        *ngIf="errorMessage | async"
                                        class="fas fa-exclamation-triangle"
                                    ></i
                                    >{{ errorMessage.value }}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="socialLinksAndNextButton">
                        <div class="social-links">
                            <div
                                *ngIf="
                                    whiteLabelService.whiteLableData.twitterUrl
                                "
                                class="social social-1 clickable"
                            >
                                <a
                                    appIcon
                                    [data]="twitterIcon"
                                    [href]="
                                        whiteLabelService.whiteLableData
                                            .twitterUrl
                                            ? whiteLabelService.whiteLableData.twitterUrl.includes(
                                                  'http'
                                              )
                                                ? whiteLabelService
                                                      .whiteLableData.twitterUrl
                                                : '//' +
                                                  whiteLabelService
                                                      .whiteLableData.twitterUrl
                                            : ''
                                    "
                                    target="_blank"
                                    rel="noopener noreferrer"
                                ></a>
                            </div>
                            <div
                                *ngIf="
                                    whiteLabelService.whiteLableData.facebookUrl
                                "
                                class="social social-2 clickable"
                            >
                                <a
                                    appIcon
                                    [data]="facebookIcon"
                                    [href]="
                                        whiteLabelService.whiteLableData
                                            .facebookUrl
                                            ? whiteLabelService.whiteLableData.facebookUrl.includes(
                                                  'http'
                                              )
                                                ? whiteLabelService
                                                      .whiteLableData
                                                      .facebookUrl
                                                : '//' +
                                                  whiteLabelService
                                                      .whiteLableData
                                                      .facebookUrl
                                            : ''
                                    "
                                    target="_blank"
                                    rel="noopener noreferrer"
                                ></a>
                            </div>
                            <div
                                *ngIf="
                                    whiteLabelService.whiteLableData.linkedinUrl
                                "
                                class="social social-3 clickable"
                            >
                                <a
                                    appIcon
                                    [data]="linkedInIcon"
                                    [href]="
                                        whiteLabelService.whiteLableData
                                            .linkedinUrl
                                            ? whiteLabelService.whiteLableData.linkedinUrl.includes(
                                                  'http'
                                              )
                                                ? whiteLabelService
                                                      .whiteLableData
                                                      .linkedinUrl
                                                : '//' +
                                                  whiteLabelService
                                                      .whiteLableData
                                                      .linkedinUrl
                                            : ''
                                    "
                                    target="_blank"
                                    rel="noopener noreferrer"
                                ></a>
                            </div>
                        </div>
                    </div>
                </div>
            </ng-container>
            <div
                [ngClass]="{
                    'blur-background-active': isPreviewTermsAndServices
                }"
            ></div>
            <div *ngIf="!whiteLabelService.isSubdomain" class="copyright">
                <ng-container
                    *ngIf="
                        whiteLabelService.centilyticsData.companyName ===
                            whiteLabelService.whiteLableData.companyName &&
                            !globalConfiguration.PREVIEW_MODE;
                        else copyright
                    "
                >
                    &copy;2025 Centilytics. All Rights Reserved
                </ng-container>

                <ng-template #copyright>
                    <div [ngClass]="hideCopyrightText ? 'hide-copyright' : ''">
                        Powered by Centilytics 2025 &copy;
                    </div>
                </ng-template>
            </div>
            <div *ngIf="whiteLabelService.isSubdomain" class="poweredByText">
                <span>Powered by Centilytics</span>
            </div>
        </div>
    </div>
</div>
