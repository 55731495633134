import {
    Component,
    EventEmitter,
    Input,
    NgZone,
    OnInit,
    Output,
} from '@angular/core';
import { IIcon } from 'src/app/shared/interfaces/icon-data/IIcon';
import { Helper } from '../../classes/Helper';
import { IconType } from '../../enums/IconType';
import { Widget } from '../../classes/Widget';
import { HttpService } from '../../services/http/http-main/http.service';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { Subject } from 'rxjs';
@Component({
    selector: 'app-table-search',
    templateUrl: './table-search.component.html',
    styleUrls: ['./table-search.component.sass'],
})
export class TableSearchComponent implements OnInit {
    @Input() data: [];
    @Input() searchPlaceholder: string;
    @Input() applyOutlineStyling: boolean = false;
    filteredSearch = [];
    @Input() isPaginationenabled?: boolean;
    @Input() widgetRef?: Widget;
    // tslint:disable-next-line: no-output-native
    @Output() changed = new EventEmitter();
    // To be used for search of paginated widget only
    searchInputSubject: Subject<string> = new Subject();
    searchIcon: IIcon = {
        class: 'search',
        type: IconType.SVG,
        extraClass: 'svg-primary-fill',
    };

    @Input() searchFunction;

    inputId: string;

    constructor() {
        this.inputId = Helper.generateUniqueKey(10);
    }

    ngOnInit(): void {
    //To be used for search of paginated widgets only 
        if (this.isPaginationenabled) {
            this.searchInputSubject
                .pipe(debounceTime(500))
                .subscribe((value) => {
                    if(value){ 
                        this.searchFunction(value);
                    }
                    else{
                        this.widgetRef.refreshWidget()
                    }
                });
        }
    }

    search(value) {
        if (this.searchFunction) {
            if (this.isPaginationenabled) {
                this.searchInputSubject.next(value);
                return;
            }
            this.searchFunction(this.inputId, value);
            return;
        }
        if (value) {
            this.filteredSearch = this.data.filter((item) =>
                JSON.stringify(item)
                    .toLowerCase()
                    .includes(value.toLowerCase().trim())
            );
        } else {
            this.filteredSearch = this.data;
        }
        this.changed.emit(this.filteredSearch);
    }
}
