import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    HostListener,
    OnInit
} from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { SwUpdate } from '@angular/service-worker';
import * as microsoftTeams from '@microsoft/teams-js';
import { GlobalConfiguration } from 'src/app/core/classes/GlobalConfiguration';
import { Helper } from 'src/app/shared/classes/Helper';
import { IconName } from 'src/app/shared/classes/Icon';
import { NotificationsService } from 'src/app/shared/services/notifications/notifications.service';
import { UserDataCacheService } from 'src/app/shared/services/user-data-cache/user-data-cache.service';
import { WhiteLabelingService } from 'src/app/shared/services/white-labeling/white-labeling.service';
import { IconType } from './shared/enums/IconType';
import { TestActions } from './shared/enums/TestActions';
import { IIcon } from './shared/interfaces/icon-data/IIcon';
import { ITestActionData } from './shared/interfaces/test-action-data/ITestActionData';
import { GlobalDataService } from './shared/services/global-data/global-data.service';
import { RedirectionHandleService } from './shared/services/redirection-handle/redirection-handle.service';
import { SessionService } from './shared/services/session/session.service';
import { PseudoConsolePreviewSourceTypes } from './shared/enums/PreviewModeSourceType';
import { ViewHttpService } from './shared/services/http/view-http/view-http.service';
import { CustomSignUpFormResponseType, CustomSignUpStatusEnum } from './shared/interfaces/ICustomSignTypes';

declare let gtag: Function;
@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.sass'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class AppComponent implements OnInit {
    isWhiteLabelDataReceived: boolean = false;
    showTestActions = false;
    widgetTesting = false;
    testingWidgetId = null;
    offlineToastId = null;
    slowNetToastId = null;
    bannerMessage: string;
    iconName: IconName;
    spinnerLoader: IIcon = {
        type: IconType.SPINNERLOADER
    };
    warningIcon: IIcon = {
        type: IconType.SVG,
        class: 'warning_filled'
    };
    crossIcon: IIcon = {
        type: IconType.SVG,
        class: 'cross'
    };
    showSignUp: boolean = false;
    public CustomSignUpFormResponseData: CustomSignUpFormResponseType = null;

    @HostListener('document:keydown.meta.alt.shift', ['$event']) keyEvent(
        event: KeyboardEvent
    ) {
        this.showTestActions = !this.showTestActions;
    }

    constructor(
        public sessionService: SessionService,
        public globalDataService: GlobalDataService,
        private redirectionHandleService: RedirectionHandleService,
        public whitelabelingService: WhiteLabelingService,
        private swUpdate: SwUpdate,
        public notificationsService: NotificationsService,
        public userDataCacheService: UserDataCacheService,
        public router: Router,
        private changeDetectorRef: ChangeDetectorRef,
        private httpService: ViewHttpService
    ) {
        /*
         * This condition is used to check if our URL contains query parameter 'previewMode=true'.
         * We add this query parameter to know that if user entered in preview mode of
         * whitelabel service.
         */

        if (
            window.location.href.includes(
                globalDataService.previewModeQueryParam
            )
        ) {
            GlobalConfiguration.PREVIEW_MODE = true;
            Helper.clearPseudoConsoleCache();
        }

        globalDataService.testingActionsObs.subscribe(
            (testAction: ITestActionData) => {
                if (testAction) {
                    if (testAction.actionType === TestActions.TEST_PORTLET) {
                        this.testingWidgetId = testAction.data;
                        this.widgetTesting = true;
                    }
                } else {
                    this.testingWidgetId = null;
                    this.widgetTesting = false;
                    this.showTestActions = false;
                }
            }
        );

        window.addEventListener('message', (event) => {
            if (event.data.pseudoConsoleView) {
                GlobalConfiguration.PREVIEW_MODE = true;
                this.globalDataService.whiteLabelingPreviewData =
                    event.data.whiteLabelData;
                if (Object.keys(event.data?.pseudoConsoleConfig || {}).length) {
                    GlobalConfiguration.pseudoConsoleConfig =
                        event.data?.pseudoConsoleConfig;
                }
                if (!this.isWhiteLabelDataReceived) {
                    this.initalizeWhitelabelData();
                }
            }
        });

        this.router.events.subscribe((event) => {
            if (event instanceof NavigationEnd) {
                if (event.urlAfterRedirects !== undefined) {
                    gtag('config', 'G-5ZDW6KW0H0', {
                        page_path: event.urlAfterRedirects
                    });
                    gtag('set', 'page', event.urlAfterRedirects);
                    gtag('send', 'pageview');
                }
            }
        });
    }

    ngOnInit() {
        // window.addEventListener('online', () => {
        //     if (this.offlineToastId) {
        //         this.notificationsService.hideToast(this.offlineToastId);
        //     }
        //     this.offlineToastId = null;
        // });
        // window.addEventListener('offline', () => {
        //     this.offlineToastId = this.notificationsService.showToast(
        //         NotificationType.ERROR,
        //         'No Internet Access',
        //         'Check your internet connection',
        //         {
        //             type: NotificationType.ERROR,
        //             title: 'No Internet Access',
        //             message: 'Check your internet connection',
        //             toastIcon: {
        //                 type: IconType.MATICON,
        //                 class: 'wifi_off'
        //             },
        //             showTime: 5000
        //         }
        //     );
        // });

        // if ((navigator as any).connection) {
        //     (navigator as any).connection.addEventListener('change', () => {
        //         const downlink = (navigator as any).connection.downlink;
        //         if (downlink <= 1 && !this.slowNetToastId) {
        //             this.slowNetToastId = this.notificationsService.showToast(
        //                 NotificationType.ERROR,
        //                 'Slow network detected',
        //                 'You may experience some delay in response',
        //                 {
        //                     type: NotificationType.ERROR,
        //                     title: 'Slow network detected',
        //                     message:
        //                         'You may experience some delay in response',
        //                     toastIcon: {
        //                         type: IconType.MATICON,
        //                         class: 'perm_scan_wifi',
        //                     },
        //                     showTime: 5000,
        //                 }
        //             );
        //         } else {
        //             if (this.slowNetToastId) {
        //                 this.notificationsService.hideToast(
        //                     this.slowNetToastId
        //                 );
        //             }
        //             this.slowNetToastId = null;
        //         }
        //     });
        // }
        if (
            GlobalConfiguration.CUSTOMER_TO_SHOW_SIGNUP.includes(this.origin) &&
            window.location.pathname === '/hpe/signup'
        ) {
            this.showSignUp = true;
            this.globalDataService.signUp.next('hpe');
        } else if (
            GlobalConfiguration.CUSTOMER_TO_SHOW_SIGNUP.includes(this.origin) &&
            window.location.pathname === '/juniper/signup'
        ) {
            this.showSignUp = true;
            this.globalDataService.signUp.next('juniper');
        }
        if (!GlobalConfiguration.PREVIEW_MODE) {
            if (!this.showSignUp) {
                this.globalDataService
                    .fetchCustomSignUpFormData(this.httpService)
                    .subscribe({
                        error: () => {
                            this.initalizeWhitelabelData(!this.showSignUp);
                        },
                        next: (res) => {
                            if (res.status === CustomSignUpStatusEnum.Active) {
                                this.CustomSignUpFormResponseData = res;
                                this.initalizeWhitelabelData(
                                    !this.showSignUp,
                                    true
                                );
                            } else {
                                this.initalizeWhitelabelData(!this.showSignUp);
                            }
                        },
                    });
            } else {
                this.initalizeWhitelabelData(!this.showSignUp);
            }
        }
    }

    initalizeWhitelabelData(
        redirectToHome?,
        ignoreSessionValidation?: boolean
    ) {
        this.isWhiteLabelDataReceived = true;
        if (ignoreSessionValidation) {
            this.whitelabelingService.getWhitelabelingData(() => {});
        } else {
            this.whitelabelingService.getWhitelabelingData(() => {
                microsoftTeams.initialize();
                microsoftTeams.getContext((Context: microsoftTeams.Context) => {
                    if (Object.keys(Context).length) {
                        this.globalDataService.viaMicrosoftTeams.next(true);
                    }
                });

                this.sessionService.verifySession(redirectToHome);
                this.redirectionHandleService.handleRedirections();
            });
        }

        this.notificationsService.getNotificationData((res, timer) => {
            this.bannerMessage = res.message;
            if (timer > 0) {
                const bannerId = this.notificationsService.showBanner(
                    this.bannerMessage
                );
                setTimeout(() => {
                    this.notificationsService.closeBanner(bannerId);
                    this.changeDetectorRef.detectChanges();
                }, timer);
            }
        });
        // this.reloadSwCache();
        // this.notificationsService.initPushNotificationConnection();
    }
    reloadSwCache() {
        if (this.swUpdate.isEnabled) {
            this.swUpdate.available.subscribe(() => {
                // TODO: Better UI required :: Confirm from user to refresh the page
                // TODO: Commented as of now
                // if (confirm(`New version available. Reload now ?`)) {
                //     window.location.reload();
                // }
            });
        }
    }
    get origin() {
        return window.location.hostname;
    }

    get isPreviewPrivacyPolicy(): boolean {
        return (
            GlobalConfiguration?.PREVIEW_MODE &&
            GlobalConfiguration?.pseudoConsoleConfig?.previewSource ===
                PseudoConsolePreviewSourceTypes.PREVIEW_SOURCE_PRIVACY_POLICY
        );
    }

}
